import type { Country } from '@/constants/countries'

const COUNTRY_TO_CURRENCY: Record<Country['code'], string> = {
  ['MX']: 'MXN',
  ['AR']: 'ARS',
  ['CO']: 'COP',
  ['US']: 'USD',
}

export function getCurrencyCodeByCountry(
  countryCode: keyof typeof COUNTRY_TO_CURRENCY,
) {
  return COUNTRY_TO_CURRENCY[countryCode]
}
